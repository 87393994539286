import React, {useContext, useEffect, useState} from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Api from '../../utils/api';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import {Link as RouterLink, Redirect} from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import {UserContext} from '../../providers/UserProvider';
import {MenuItem, Select} from '@material-ui/core';

export default function Form(props) {
    const user = useContext(UserContext);

    const [admin, setAdmin] = useState({
        id: '',
        enabled: false,
        role: '',
        created_at: '',
        updated_at: '',
    });
    const [alert, setAlert] = useState({
        open: false,
        success: false,
        text: '',
    });
    const [loading, setLoading] = useState(false);

    const [redirect, setRedirect] = useState(false);

    function errorAlert(err) {
        setAlert({open: true, success: false, text: err[0] === undefined ? err : err[0].toUpperCase() + err.slice(1)})
    }

    useEffect(() => {
        if (!user.accessToken) return

        if (props.id) {
            setLoading(true);

            Api().admins.get(user.accessToken, props.id)
                .then(admin => setAdmin(admin))
                .catch(err => errorAlert(err))
                .then(() => setLoading(false))
        }
    }, [user.accessToken, props.id]);

    const handleChanges = event => {
        let {name, value} = event.target

        if (name === 'enabled') {
            value = event.target.checked
        }

        setAdmin(prevState => ({...prevState, [name]: value}))
    }

    const createAdmin = () => {
        setLoading(true);

        Api().admins.create(user.accessToken, admin)
            .then(data => {
                user.setAlert('Admin successfully saved!')
                setRedirect(true)
            })
            .catch(err => errorAlert(err))
            .then(() => setLoading(false))
    }

    const updateAdmin = () => {
        setLoading(true)

        Api().admins.update(user.accessToken, admin.id, admin)
            .then(response => {
                user.setAlert('Admin successfully updated!')
                setRedirect(true)
            })
            .catch(err => errorAlert(err))
            .then(() => setLoading(false))
    }

    if (redirect) {
        return <Redirect to="/admins"/>
    }

    return <Paper>
        {loading && <LinearProgress/>}

        <Box p={3}>
            {alert.open && <Alert style={{marginBottom: 10}} severity={alert.success ? 'success' : 'error'} onClose={
                () => setAlert({...alert, open: false})
            }>{alert.text}</Alert>}

            <FormControl fullWidth margin="normal">
                <TextField
                    disabled={loading}
                    label="Email"
                    multiline
                    name="id"
                    onChange={handleChanges}
                    value={admin.id}
                />
            </FormControl>

            <FormControl fullWidth margin="normal">
                <Select
                    labelId="Role"
                    name="role"
                    value={admin.role}
                    onChange={handleChanges}
                >
                    <MenuItem value="admin">Admin</MenuItem>
                    <MenuItem value="editor">Editor</MenuItem>
                    <MenuItem value="viewer">Viewer</MenuItem>
                </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
                <FormControlLabel label="Enabled" control={<Switch
                    checked={admin.enabled}
                    disabled={loading}
                    name="enabled"
                    onChange={handleChanges}
                />}/>
            </FormControl>

            <Box mt={2}>
                {props.id && <Button variant="contained" disabled={loading} color="primary" onClick={updateAdmin}>Update</Button>}
                {!props.id && <Button variant="contained" disabled={loading} color="primary" onClick={createAdmin}>Create</Button>}
                <Button style={{marginLeft: 10}} component={RouterLink} to="/admins">Cancel</Button>
            </Box>
        </Box>
    </Paper>
}
