import React, {useContext, useEffect, useState} from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Api from '../../utils/api';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import {Link as RouterLink, Redirect} from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import {UserContext} from '../../providers/UserProvider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import List from '@material-ui/core/List';
import {ListItemText, Radio, RadioGroup} from '@material-ui/core';

export default function Form(props) {
    const user = useContext(UserContext);
    const [categories, setCategories] = useState([]);
    const [affirmation, setAffirmation] = useState({
        id: '',
        text: '',
        name: '',
        visible: false,
        category: null,
    });
    const [alert, setAlert] = useState({
        open: false,
        success: false,
        text: '',
    });
    const [loading, setLoading] = useState(false);

    const [redirect, setRedirect] = useState(false);

    function errorAlert(err) {
        setAlert({open: true, success: false, text: err[0] === undefined ? err : err[0].toUpperCase() + err.slice(1)})
    }

    useEffect(() => {
        if (!user.accessToken) return

        setLoading(true);

        Api().categories.list(user.accessToken, user.platform)
            .then(categories => categories.filter(category => category.visible))
            .then(categories => setCategories(categories))
            .catch(err => errorAlert(err))
            .then(() => {
                if (props.id) {
                    Api().affirmations.get(user.accessToken, props.id, user.platform)
                        .then(affirmation => setAffirmation(affirmation))
                        .catch(err => errorAlert(err))
                        .then(() => setLoading(false))

                } else {
                    setLoading(false)
                }
            })
    }, [user.accessToken, user.platform, props.id]);

    const handleChanges = event => {
        let {name, value} = event.target

        if (name === 'visible') {
            value = event.target.checked
        }

        setAffirmation(prevState => ({...prevState, [name]: value}))
    }

    const createAffirmation = () => {
        setLoading(true);

        Api().affirmations.create(user.accessToken, affirmation, user.platform)
            .then(() => {
                user.setAlert('Affirmation successfully saved!')
                setRedirect(true)
            })
            .catch(err => errorAlert(err))
            .then(() => setLoading(false))
    }

    const updateAffirmation = () => {
        setLoading(true)

        Api().affirmations.update(user.accessToken, affirmation.id, affirmation, user.platform)
            .then(() => {
                user.setAlert('Affirmation successfully updated!')
                setRedirect(true)
            })
            .catch(err => errorAlert(err))
            .then(() => setLoading(false))
    }

    const handleToggleCategory = (category) => {
        setAffirmation({...affirmation, category: category.target.value})
    }

    if (redirect) {
        return <Redirect to="/affirmations"/>
    }

    return <Paper>
        {loading && <LinearProgress/>}
        <Box p={3}>
            {alert.open && <Alert style={{marginBottom: 10}} severity={alert.success ? 'success' : 'error'} onClose={
                () => setAlert({...alert, open: false})
            }>{alert.text}</Alert>}

            <FormControl fullWidth margin="normal">
                <TextField
                    disabled={loading}
                    label="Text"
                    multiline
                    name="text"
                    onChange={handleChanges}
                    value={affirmation.text}
                />
            </FormControl>

            <FormControl fullWidth margin="normal">
                <TextField
                    disabled={loading}
                    label="Name (optional/autogenerated)"
                    name="name"
                    onChange={handleChanges}
                    value={affirmation.name}
                />
            </FormControl>

            <FormControl fullWidth margin="normal">
                <FormControlLabel label="Visible" control={<Switch
                    checked={affirmation.visible}
                    disabled={loading}
                    name="visible"
                    onChange={handleChanges}
                />}/>
            </FormControl>

            <Paper>
                <FormControl component="fieldset" style={{width: '100%'}}>
                    <RadioGroup value={affirmation.category} onChange={handleToggleCategory}>
                        <List dense className="hovered">
                            {categories && categories.map(category => (
                                <ListItem key={category.id}>
                                    <ListItemIcon>
                                        {category.visible && <VisibilityIcon style={{color: 'mediumseagreen'}}/>}
                                        {!category.visible && <VisibilityOffIcon style={{color: '#bbb'}}/>}
                                    </ListItemIcon>
                                    <ListItemText>
                                        <FormControlLabel
                                            disabled={loading}
                                            value={category.id}
                                            control={<Radio style={{padding: '0 9px'}}/>}
                                            label={category.name}
                                        />
                                    </ListItemText>
                                </ListItem>
                            ))}
                        </List>
                    </RadioGroup>
                </FormControl>
            </Paper>

            <Box mt={2}>
                {affirmation.id && <Button variant="contained" disabled={loading} color="primary" onClick={updateAffirmation}>Update</Button>}
                {!affirmation.id && <Button variant="contained" disabled={loading} color="primary" onClick={createAffirmation}>Create</Button>}
                <Button style={{marginLeft: 10}} component={RouterLink} to="/affirmations">Cancel</Button>
            </Box>
        </Box>
    </Paper>
}
