import Alert from '@material-ui/lab/Alert';
import Api from '../../utils/api';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Image from '../Image';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import React, {useContext, useEffect, useState} from 'react';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import ToggleButton from '@material-ui/lab/ToggleButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import {Grid} from '@material-ui/core';
import {Link as RouterLink, Redirect} from 'react-router-dom';
import {UserContext} from '../../providers/UserProvider';

export default function Form(props) {
    const user = useContext(UserContext);

    const [category, setCategory] = useState({
        id: '',
        visible: false,
        premium: false,
        is_new: false,
        category: '',
        image: '',
        name: '',
        affirmations: [],
        improvement_area: '',
    });
    const [newAffirmation, setNewAffirmation] = useState({
        visible: false,
        text: '',
        category: '',
    });
    const [alert, setAlert] = useState({
        open: false,
        success: false,
        text: '',
    });
    const [loading, setLoading] = useState(false);

    const [affirmations, setAffirmations] = useState([]);
    const [redirect, setRedirect] = useState(false);

    function errorAlert(err) {
        setAlert({open: true, success: false, text: err[0] === undefined ? err : err[0].toUpperCase() + err.slice(1)})
    }

    useEffect(() => {
        if (!user.accessToken) return

        setLoading(true);

        Api().affirmations.list(user.accessToken, {}, user.platform)
            .then(data => {
                setAffirmations(data)

                if (props.id) {
                    Api().categories.get(user.accessToken, props.id, user.platform)
                        .then(category => setCategory(category))
                        .catch(err => errorAlert(err))
                        .then(() => setLoading(false))
                }

            })
            .catch(err => errorAlert(err))
            .then(() => setLoading(false))
    }, [user.accessToken, user.platform, props.id]);

    const handleChanges = event => {
        let {name, value} = event.target

        if (name === 'visible' || name === 'premium' || name === 'is_new') {
            value = event.target.checked
        }

        setCategory(prevState => ({...prevState, [name]: value}))
    }

    const createCategory = () => {
        setLoading(true);

        const data = {...category};
        data.affirmations = data.affirmations.map(item => item.id)

        Api().categories.create(user.accessToken, data, user.platform)
            .then(data => {
                user.setAlert('Category successfully saved!')
                setRedirect(true)
            })
            .catch(err => errorAlert(err))
            .then(() => setLoading(false))
    }

    const updateCategory = () => {
        setLoading(true)

        const data = {...category};
        data.affirmations = data.affirmations.map(item => item.id)

        Api().categories.update(user.accessToken, category.id, data, user.platform)
            .then(() => {
                user.setAlert('Category successfully updated!')
                setRedirect(true)
            })
            .catch(err => errorAlert(err))
            .then(() => setLoading(false))
    }

    const handleImageUpload = ({target}) => {
        setLoading(true);

        const formData = new FormData();
        formData.append('file', target.files[0]);

        Api().uploadImage(user.accessToken, formData, user.platform)
            .then(response => setCategory({...category, image: response.href}))
            .catch(err => errorAlert(err))
            .then(() => setLoading(false))
    }

    const handleSelectAffirmation = (e, affirmation) => {
        if (affirmation === null) return

        // ignore if already added
        const existing = category.affirmations.find(item => item.id === affirmation.id)
        if (existing) return

        // add affirmation
        category.affirmations.push(affirmations.find(item => item.id === affirmation.id))
        setCategory({...category, affirmations: category.affirmations})
    }

    const handleDeleteAffirmation = (affirmation) => {
        setCategory({...category, affirmations: category.affirmations.filter(item => item.id !== affirmation.id)})
    }

    const handleAffirmationChanges = event => {
        let {name, value} = event.target

        if (name === 'visible') {
            value = event.target.checked
        }

        setNewAffirmation(prevState => ({...prevState, [name]: value}))
    }

    const createAndAttachAffirmation = () => {
        setLoading(true);

        newAffirmation.category = category.id

        Api().affirmations.create(user.accessToken, newAffirmation, user.platform)
            .then(data => {
                let affirmationsList = affirmations;
                affirmationsList.push(data)
                setAffirmations(affirmationsList)

                category.affirmations.push(data)
                setCategory({...category, affirmations: category.affirmations})

                setNewAffirmation({...newAffirmation, visible: false, text: ''})
            })
            .catch(err => errorAlert(err))
            .then(() => setLoading(false))
    }

    const improvementsArea = [
        {id: '1', text: 'Self Care'},
        {id: '2', text: 'Personal Growth'},
        {id: '3', text: 'Love and Relationships'},
        {id: '4', text: 'Stress and Anxiety'},
        {id: '5', text: 'Positive Thinking'},
        {id: '6', text: 'Health & Wellbeing'},
        {id: '7', text: 'Gratitude'},
        {id: '8', text: 'Work & Career'},
    ]

    if (redirect) {
        return <Redirect to="/categories"/>
    }

    return <Paper>
        {loading && <LinearProgress/>}
        <Box p={3}>
            {alert.open && <Alert style={{marginBottom: 10}} severity={alert.success ? 'success' : 'error'} onClose={
                () => setAlert({...alert, open: false})
            }>{alert.text}</Alert>}

            <Grid container spacing={4}>
                <Grid item xs={6}>
                    <FormControl fullWidth margin="normal">
                        <TextField
                            disabled={loading}
                            label="Category Name"
                            multiline
                            name="name"
                            onChange={handleChanges}
                            value={category.name}
                        />
                    </FormControl>

                    <FormControl fullWidth margin="normal">
                        <FormControlLabel label="Visible" control={<Switch
                            checked={category.visible}
                            disabled={loading}
                            name="visible"
                            onChange={handleChanges}
                        />}/>
                        <FormControlLabel label="Premium" control={<Switch
                            checked={category.premium}
                            disabled={loading}
                            name="premium"
                            onChange={handleChanges}
                        />}/>
                        <FormControlLabel label="New" control={<Switch
                            checked={category.is_new}
                            disabled={loading}
                            name="is_new"
                            onChange={handleChanges}
                        />}/>
                    </FormControl>

                    <div id="improvements-area">
                        {improvementsArea.map((area, index) => {
                            return <div key={area.id} className="improvement-area">
                                <ToggleButton
                                    value={area.id}
                                    disabled={loading}
                                    selected={category.improvement_area === area.id}
                                    onChange={() => {
                                        setCategory({...category, improvement_area: area.id})
                                    }}
                                >
                                    {area.text}
                                </ToggleButton>

                                {index % 2 !== 0 && <br/>}
                            </div>
                        })}
                    </div>

                </Grid>
                <Grid item xs={6}>
                    <Paper>
                        <Box p={3}>
                            {category.image && <Image
                                disableSpinner
                                src={category.image}
                                aspectRatio={(16 / 9)}
                                style={{marginBottom: 20}}
                            />}

                            <input
                                hidden
                                accept="image/*"
                                id="icon-button-photo"
                                onChange={handleImageUpload}
                                type="file"
                            />
                            <label htmlFor="icon-button-photo">
                                <Button disabled={loading} component="span" variant="contained" startIcon={<CloudUploadIcon/>}>
                                    Upload image
                                </Button>
                            </label>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>

            <Box mt={2}>
                <Card>
                    <CardContent>
                        <Box display="flex">
                            <Box flexGrow={1} pr={2}>
                                <FormControl fullWidth>
                                    <TextField
                                        disabled={loading}
                                        label="New affirmation text"
                                        multiline
                                        name="text"
                                        onChange={handleAffirmationChanges}
                                        value={newAffirmation.text}
                                    />
                                </FormControl>
                            </Box>
                            <Box pr={2}>
                                <FormControl margin="normal">
                                    <FormControlLabel label="Visible" control={<Switch
                                        checked={newAffirmation.visible}
                                        disabled={loading}
                                        name="visible"
                                        onChange={handleAffirmationChanges}
                                    />}/>
                                </FormControl>
                            </Box>
                            <Box>
                                <FormControl margin="normal">
                                    <Button variant="contained" disabled={loading} onClick={createAndAttachAffirmation}>Save & attach</Button>
                                </FormControl>
                            </Box>
                        </Box>

                        <FormControl fullWidth>
                            <Autocomplete
                                clearOnEscape
                                disabled={loading}
                                onChange={handleSelectAffirmation}
                                options={affirmations}
                                getOptionLabel={(option) => option.text}
                                getOptionSelected={(opt, val) => {
                                    return opt.id === val.id
                                }}
                                filterOptions={(affirmations, state) => {
                                    const existingIds = category.affirmations.map(item => item.id);
                                    const withoutAdded = affirmations.filter(item => !existingIds.includes(item.id));

                                    if (state.inputValue !== '') {
                                        return withoutAdded.filter(item => item.text.includes(state.inputValue))
                                    }

                                    return withoutAdded
                                }}
                                filterSelectedOptions
                                renderInput={params =>
                                    <TextField{...params} label="Affirmations" name="affirmation" placeholder="Select" variant="standard"/>
                                }
                                renderOption={(option) => (
                                    <React.Fragment>
                                        {option.visible && <VisibilityIcon style={{color: 'mediumseagreen'}}/>}
                                        {!option.visible && <VisibilityOffIcon style={{color: '#bbb'}}/>}
                                        &nbsp;
                                        {option.text}
                                    </React.Fragment>
                                )}
                            />
                        </FormControl>

                        <List dense className='hovered'>
                            {category.affirmations && category.affirmations.map(affirmation => (
                                <ListItem key={affirmation.id}>
                                    <ListItemIcon>
                                        {affirmation.visible && <VisibilityIcon style={{color: 'mediumseagreen'}}/>}
                                        {!affirmation.visible && <VisibilityOffIcon style={{color: '#bbb'}}/>}
                                    </ListItemIcon>
                                    <ListItemText primary={affirmation.text}/>
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteAffirmation(affirmation)}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </CardContent>
                </Card>
            </Box>

            <Box mt={2}>
                {category.id && <Button variant="contained" disabled={loading} color="primary" onClick={updateCategory}>Update</Button>}
                {!category.id && <Button variant="contained" disabled={loading} color="primary" onClick={createCategory}>Create</Button>}
                <Button style={{marginLeft: 10}} component={RouterLink} to='/categories'>Cancel</Button>
            </Box>
        </Box>
    </Paper>
}
