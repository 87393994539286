import AddIcon from '@material-ui/icons/Add';
import Alert from '@material-ui/lab/Alert';
import Api from '../../utils/api';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Moment from 'react-moment';
import Paper from '@material-ui/core/Paper';
import PublishIcon from '@material-ui/icons/Publish';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import {ButtonGroup} from '@material-ui/core';
import {Link as RouterLink} from 'react-router-dom';
import {UserContext} from '../../providers/UserProvider';
import {debounce} from 'underscore';
import LoadMoreButton from '../base/buttons/LoadMoreButton';

export default function ListAffirmations() {
    const user = useContext(UserContext);
    const [items, setItems] = useState([]);
    const [deleteBox, setDeleteBox] = useState({
        open: false,
        id: '',
        message: '',
    });
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({
        open: false,
        success: false,
        text: '',
    });

    const [filter, setFilter] = useState({
        visible: '',
        category: '',
        text: '',
        name: '',
        last_id: '',
    });

    const [selected, setSelected] = useState([]);
    const [categories, setCategories] = useState([]);

    const [likesSort, setLikesSort] = useState(null);

    function errorAlert(err) {
        setAlert({open: true, success: false, text: err[0] === undefined ? err : err[0].toUpperCase() + err.slice(1)})
    }

    useEffect(() => {
        if (!user.accessToken) return

        setLoading(true);

        Api().affirmations.list(user.accessToken, {}, user.platform)
            .then(data => {
                setItems(data)
                if (data.length > 0) {
                    setFilter({...filter, last_id: data[data.length - 1].id})
                }

                Api().categories.list(user.accessToken, user.platform)
                    .then(categories => setCategories(categories))
                    .catch(err => errorAlert(err))
            })
            .catch(err => errorAlert(err))
            .then(() => setLoading(false))
    }, [user.accessToken, user.platform, setLoading]);

    const handleOpenDeleteDialog = (id, text) => {
        setDeleteBox({id: id, message: text, open: true});
    }

    const handleCloseDeleteDialog = () => {
        setDeleteBox({...deleteBox, open: false});
    }

    const handleDelete = () => {
        setLoading(true);

        Api().affirmations.delete(user.accessToken, deleteBox.id, user.platform)
            .catch(err => errorAlert(err))
            .then(() => {

                Api().affirmations.list(user.accessToken, {}, user.platform)
                    .then(data => setItems(data))
                    .catch(err => errorAlert(err))
                    .then(() => setLoading(false))

            })
            .then(() => {
                setAlert({open: true, success: true, text: 'Affirmation successfully deleted'})
                handleCloseDeleteDialog()
            })
    }

    const handleFiltering = (event) => {
        const {name, value} = event.target

        const filteredData = {...filter, [name]: value}
        setFilter(filteredData)

        debouncedSave(filteredData);
    }

    const debouncedSave = useCallback(debounce(filteredData => filterData(filteredData), 500), [user.platform]);

    const filteredItems = () => {
        let list = items;

        if (likesSort === 'ask') {
            list.sort((a, b) => {
                if (a.likes > b.likes) {
                    return -1;
                }
                if (a.likes > b.likes) {
                    return 1;
                }

                return 0;
            })
        } else if (likesSort === 'desk') {
            list.sort((a, b) => {
                if (a.likes < b.likes) {
                    return -1;
                }
                if (a.likes < b.likes) {
                    return 1;
                }

                return 0;
            })
        }

        return list;
    }

    const filterData = (filteredData) => {
        if (!user.accessToken) return

        setAlert({...alert, open: false})
        setLoading(true);
        Api().affirmations.list(user.accessToken, {...filteredData, last_id: ''}, user.platform)
            .then(data => {
                setItems(data)

                if (data.length) {
                    const lastId = data[data.length - 1].id;
                    if (lastId) {
                        setFilter({...filteredData, last_id: lastId})
                    }
                }
            })
            .catch(err => errorAlert(err))
            .then(() => setLoading(false))
    }

    const loadMore = () => {
        if (!user.accessToken) return

        const filteredData = {...filter}

        setAlert({...alert, open: false})
        setLoading(true);
        Api().affirmations.list(user.accessToken, filteredData, user.platform)
            .then(data => {
                setItems(items.concat(data))

                if (data.length) {
                    const lastId = data[data.length - 1].id;
                    if (lastId) {
                        setFilter({...filter, last_id: lastId})
                    }
                }
            })
            .catch(err => errorAlert(err))
            .then(() => setLoading(false))
    }

    const handleChangeSelected = (event) => {
        if (event.target.checked) {
            setSelected([...selected, event.target.value]);
        } else {
            setSelected([...selected.filter(id => id !== event.target.value)])
        }
    }

    const handleSelectALL = () => {
        setSelected(items.map(item => item.id))
    }

    const handleSelectNone = () => {
        setSelected([])
    }

    const handleToggleSelectedVisible = () => {
        setLoading(true);

        Api().affirmations.toggle(user.accessToken, selected, user.platform)
            .then(() => {
                Api().affirmations.list(user.accessToken, {}, user.platform)
                    .then(data => setItems(data))
                    .catch(err => errorAlert(err))
                    .then(() => setLoading(false))
            })
            .catch(err => errorAlert(err))
    }

    const handleShowDeleteSelected = () => {
        setDeleteBox({...deleteBox, id: '', message: 'Delete selected items?', open: true});
    }

    const handleDeleteSelected = () => {
        setLoading(true);

        Api().affirmations.deleteList(user.accessToken, selected, user.platform)
            .then(() => {
                Api().affirmations.list(user.accessToken, {}, user.platform)
                    .then(data => setItems(data))
                    .catch(err => errorAlert(err))
                    .then(() => setLoading(false))

                setSelected([]);
                handleCloseDeleteDialog();
            })
            .catch(err => errorAlert(err))
    }

    const Category = (props) => {
        const affirmation = props.item;

        const length = affirmation.categories.length;

        if (length === 0) return <></>

        const categories = [...affirmation.categories];
        const firstCategory = categories.shift();

        if (length === 1) {
            return user.role === 'viewer'
                ? firstCategory.name
                : <RouterLink className="content-link" to={'/categories/update/' + firstCategory.id}>{firstCategory.name}</RouterLink>
        }

        if (length > 1) {
            return <>
                {
                    user.role === 'viewer'
                        ? firstCategory.name
                        : <RouterLink className="content-link" to={'/categories/update/' + firstCategory.id}>{firstCategory.name}</RouterLink>
                }
                &nbsp;
                <Tooltip title={categories.map(category => category.name).join(', ')}>
                    <Chip size="small" label={'+' + (length - 1)}/>
                </Tooltip>
            </>
        }

        return <></>
    }

    return <>
        <Box display="flex" pb={2}>
            <Box flexGrow={1}>
                <Typography variant="h6">
                    Affirmations
                </Typography>
            </Box>
            <Box>
                {user.role !== 'viewer' && <>
                    <ButtonGroup>
                        <Button startIcon={<AddIcon/>} color="primary" variant="contained" component={RouterLink} to="/affirmations/create">
                            Create
                        </Button>

                        <Button startIcon={<PublishIcon/>} color="secondary" variant="contained" component={RouterLink}
                                to="/affirmations/import">Import</Button>
                    </ButtonGroup>
                </>}
            </Box>
        </Box>

        {alert.open && <Alert style={{marginBottom: 10}} severity={alert.success ? 'success' : 'error'} onClose={
            () => setAlert({...alert, open: false})
        }>{alert.text}</Alert>}

        {loading && <LinearProgress/>}
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell/>
                        <TableCell>ID</TableCell>
                        <TableCell>Visible</TableCell>
                        <TableCell>Category</TableCell>
                        <TableCell>Text</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>
                            <span className="sort-link" onClick={() => {
                                if (likesSort === 'desk') {
                                    setLikesSort('ask')
                                } else {
                                    setLikesSort('desk')
                                }
                            }}>Likes
                            </span>
                            {!loading && likesSort === 'desk' && <ArrowUpwardIcon style={{fontSize: 18}}/>}
                            {!loading && likesSort === 'ask' && <ArrowDownwardIcon style={{fontSize: 18}}/>}
                        </TableCell>
                        <TableCell>Created at</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow className="filter-row">
                        <TableCell/>
                        <TableCell/>
                        <TableCell>
                            <Select name="visible" value={filter.visible} onChange={handleFiltering}>
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value="yes">Yes</MenuItem>
                                <MenuItem value="no">No</MenuItem>
                            </Select>
                        </TableCell>
                        <TableCell>
                            <Tooltip arrow placement="top" title="Select category to view all items">
                                <Select name="category" value={filter.category} onChange={handleFiltering} style={{width: '95%'}}>
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {categories.map(category => <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>)}
                                </Select>
                            </Tooltip>
                        </TableCell>
                        <TableCell>
                            <TextField name="text" value={filter.text} onChange={handleFiltering} style={{width: '95%'}}/>
                        </TableCell>
                        <TableCell>
                            <TextField name="text" value={filter.name} onChange={handleFiltering} style={{width: '100%'}}/>
                        </TableCell>
                        <TableCell/>
                        <TableCell/>
                    </TableRow>
                    {items && items.length > 0 && selected.length > 0 && <TableRow className="filter-row">
                        <TableCell colSpan={11} style={{padding: 16}} className="select-options">
                            Select -&nbsp;
                            <Button color="primary" onClick={handleSelectALL}>all</Button>,&nbsp;
                            <Button color="primary" onClick={handleSelectNone}>none</Button>.
                            Actions with selected -&nbsp;
                            <Button color="primary" onClick={handleToggleSelectedVisible}>toggle visible</Button>,&nbsp;
                            <Button color="primary" onClick={handleShowDeleteSelected}>delete</Button>.
                        </TableCell>
                    </TableRow>}
                    {items && filteredItems().map(row => (
                        <TableRow key={row.id}>
                            <TableCell>
                                {user.role !== 'viewer' && <Checkbox value={row.id} checked={selected.includes(row.id)} onChange={handleChangeSelected}/>}
                            </TableCell>
                            <TableCell>{row.id}</TableCell>
                            <TableCell>
                                {row.visible && <VisibilityIcon style={{color: 'mediumseagreen'}}/>}
                                {!row.visible && <VisibilityOffIcon style={{color: '#bbb'}}/>}
                            </TableCell>
                            <TableCell>
                                <Category item={row}/>
                            </TableCell>
                            <TableCell>
                                {
                                    user.role === 'viewer'
                                        ? row.text
                                        : <RouterLink className="content-link" to={'/affirmations/update/' + row.id}>{row.text}</RouterLink>
                                }
                            </TableCell>
                            <TableCell>
                                {
                                    user.role === 'viewer'
                                        ? row.name
                                        : <RouterLink className="content-link" to={'/affirmations/update/' + row.id}>{row.name}</RouterLink>
                                }
                            </TableCell>
                            <TableCell>{row.likes}</TableCell>
                            <TableCell>
                                <Moment date={row.created_at} format="YYYY/MM/DD HH:mm:ss"/>
                            </TableCell>
                            <TableCell>
                                {user.role !== 'viewer' && <>
                                    <IconButton
                                        size="small"
                                        component={RouterLink}
                                        to={'/affirmations/update/' + row.id}
                                        style={{marginRight: 5}}
                                    >
                                        <EditIcon/>
                                    </IconButton>
                                    <IconButton
                                        size="small"
                                        onClick={() => handleOpenDeleteDialog(row.id, row.text)}
                                        color="secondary"
                                    >
                                        <DeleteIcon/>
                                    </IconButton>
                                </>}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

        <Box mt={2} style={{textAlign: 'center'}}>
            <p>Items count: {items.length}</p>
            <LoadMoreButton disabled={loading} variant="contained" color="primary" onClick={loadMore}>Load more</LoadMoreButton>
        </Box>

        <Dialog open={deleteBox.open} onClose={() => handleCloseDeleteDialog()}>
            <DialogTitle>Delete affirmation</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {deleteBox.id && `Affirmation #${deleteBox.id} \`${deleteBox.message}\` will be deleted`}
                    {!deleteBox.id && `${deleteBox.message}`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleCloseDeleteDialog()} autoFocus color="primary">Cancel</Button>
                <Button onClick={() => deleteBox.id ? handleDelete() : handleDeleteSelected()} variant="contained" color="secondary">Delete</Button>
            </DialogActions>
        </Dialog>
    </>;
}
