function getApiDomain() {
    if (window.location.hostname.includes('localhost')) {
        return 'http://localhost'
    } else if (window.location.hostname.includes('stage')) {
        return 'https://admin-api.manifest.boosters-stage.com'
    } else {
        return 'https://admin-api.manifest.boosters-work.com'
    }
}

function Api() {
    function authHeaders(accessToken) {
        let headers = new Headers();
        headers.set('Authorization', accessToken);

        return headers;
    }

    function parseError(json) {
        let err;
        if (json.hasOwnProperty('error')) {
            err = json.error;
        } else if (json.hasOwnProperty('Message')) {
            err = json.Message;
        } else if (json.hasOwnProperty('message')) {
            err = json.message;
        } else {
            err = json.toString();
        }

        return err;
    }

    return {
        affirmations: {
            list: (accessToken, filter, platform) => {
                let queryString = new URLSearchParams(filter).toString()
                if (queryString !== '') {
                    queryString = '?' + queryString + '&platform=' + platform
                } else {
                    queryString = '?platform=' + platform
                }

                return fetch(getApiDomain() + '/affirmations' + queryString, {method: 'GET', headers: authHeaders(accessToken)})
                    .then(resp => {
                        if (!resp.ok) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                        return resp.json()
                    })
            },
            create: (accessToken, data, platform) => {
                return fetch(getApiDomain() + '/affirmations?platform=' + platform, {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: authHeaders(accessToken),
                })
                    .then(resp => {
                        if (!resp.ok) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                        return resp.json()
                    })
            },
            get: (accessToken, id, platform) => {
                return fetch(getApiDomain() + '/affirmations/' + id + '?platform=' + platform, {method: 'GET', headers: authHeaders(accessToken)})
                    .then(resp => {
                        if (!resp.ok) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                        return resp.json()
                    })
            },
            update: (accessToken, id, data, platform) => {
                return fetch(getApiDomain() + '/affirmations/' + id + '?platform=' + platform, {
                    method: 'PUT',
                    body: JSON.stringify(data),
                    headers: authHeaders(accessToken),
                })
                    .then(resp => {
                        if (!resp.ok) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                        return resp.json()
                    })
            },
            delete: (accessToken, id, platform) => {
                return fetch(getApiDomain() + '/affirmations/' + id + '?platform=' + platform, {method: 'DELETE', headers: authHeaders(accessToken)})
                    .then(resp => {
                        if (resp.status !== 204) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                    })
            },
            deleteList: (accessToken, ids, platform) => {
                return fetch(getApiDomain() + '/affirmations?platform=' + platform, {
                    method: 'DELETE',
                    body: JSON.stringify(ids),
                    headers: authHeaders(accessToken),
                })
                    .then(resp => {
                        if (resp.status !== 204) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                    })
            },
            toggle: (accessToken, visible, platform) => {
                const data = {visible: visible}
                return fetch(getApiDomain() + '/affirmations/toggle?platform=' + platform, {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: authHeaders(accessToken),
                })
                    .then(resp => {
                        if (!resp.ok) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                        return resp.json()
                    })
            },
            import: (accessToken, data, platform) => {
                return fetch(getApiDomain() + '/affirmations/import?platform=' + platform, {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: authHeaders(accessToken),
                })
                    .then(resp => {
                        if (!resp.ok) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                        return resp.json()
                    })
            },
        },
        categories: {
            list: (accessToken, platform) => {
                return fetch(getApiDomain() + '/categories?platform=' + platform, {method: 'GET', headers: authHeaders(accessToken)})
                    .then(resp => {
                        if (!resp.ok) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                        return resp.json()
                    })
            },
            create: (accessToken, data, platform) => {
                return fetch(getApiDomain() + '/categories?platform=' + platform, {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: authHeaders(accessToken),
                })
                    .then(resp => {
                        if (!resp.ok) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                        return resp.json()
                    })
            },
            get: (accessToken, id, platform) => {
                return fetch(getApiDomain() + '/categories/' + id + '?platform=' + platform, {method: 'GET', headers: authHeaders(accessToken)})
                    .then(resp => {
                        if (!resp.ok) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                        return resp.json()
                    })
            },
            update: (accessToken, id, data, platform) => {
                return fetch(getApiDomain() + '/categories/' + id + '?platform=' + platform, {
                    method: 'PUT',
                    body: JSON.stringify(data),
                    headers: authHeaders(accessToken),
                })
                    .then(resp => {
                        if (!resp.ok) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                        return resp.json()
                    })
            },
            delete: (accessToken, id, platform) => {
                return fetch(getApiDomain() + '/categories/' + id + '?platform=' + platform, {method: 'DELETE', headers: authHeaders(accessToken)})
                    .then(resp => {
                        if (resp.status !== 204) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                    })
            },
            deleteList: (accessToken, ids, platform) => {
                return fetch(getApiDomain() + '/categories?platform=' + platform, {
                    method: 'DELETE',
                    body: JSON.stringify(ids),
                    headers: authHeaders(accessToken),
                })
                    .then(resp => {
                        if (resp.status !== 204) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                    })
            },
            toggle: (accessToken, visible, premium, is_new, platform) => {
                const data = {visible: visible, premium: premium, is_new: is_new}
                return fetch(getApiDomain() + '/categories/toggle?platform=' + platform, {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: authHeaders(accessToken),
                })
                    .then(resp => {
                        if (!resp.ok) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                        return resp.json()
                    })
            },
            reorder: (accessToken, id, from, to, platform) => {
                return fetch(getApiDomain() + '/categories/reorder/' + id + '/' + from + '/' + to + '?platform=' + platform, {
                    method: 'POST',
                    headers: authHeaders(accessToken),
                })
                    .then(resp => {
                        if (!resp.ok) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                        return resp.json()
                    })
            },
        },
        themes: {
            list: (accessToken, platform) => {
                return fetch(getApiDomain() + '/themes?platform=' + platform, {method: 'GET', headers: authHeaders(accessToken)})
                    .then(resp => {
                        if (!resp.ok) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                        return resp.json()
                    })
            },
            create: (accessToken, data, platform) => {
                return fetch(getApiDomain() + '/themes?platform=' + platform, {method: 'POST', body: JSON.stringify(data), headers: authHeaders(accessToken)})
                    .then(resp => {
                        if (!resp.ok) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                        return resp.json()
                    })
            },
            get: (accessToken, id, platform) => {
                return fetch(getApiDomain() + '/themes/' + id + '?platform=' + platform, {method: 'GET', headers: authHeaders(accessToken)})
                    .then(resp => {
                        if (!resp.ok) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                        return resp.json()
                    })
            },
            update: (accessToken, id, data, platform) => {
                return fetch(getApiDomain() + '/themes/' + id + '?platform=' + platform, {
                    method: 'PUT',
                    body: JSON.stringify(data),
                    headers: authHeaders(accessToken),
                })
                    .then(resp => {
                        if (!resp.ok) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                        return resp.json()
                    })
            },
            delete: (accessToken, id, platform) => {
                return fetch(getApiDomain() + '/themes/' + id + '?platform=' + platform, {method: 'DELETE', headers: authHeaders(accessToken)})
                    .then(resp => {
                        if (resp.status !== 204) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                    })
            },
            deleteList: (accessToken, ids, platform) => {
                return fetch(getApiDomain() + '/themes?platform=' + platform, {method: 'DELETE', body: JSON.stringify(ids), headers: authHeaders(accessToken)})
                    .then(resp => {
                        if (resp.status !== 204) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                    })
            },
            toggle: (accessToken, visible, premium, painIcon, is_new, platform) => {
                const data = {visible: visible, premium: premium, paint_icon: painIcon, is_new: is_new}
                return fetch(getApiDomain() + '/themes/toggle?platform=' + platform, {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: authHeaders(accessToken),
                })
                    .then(resp => {
                        if (!resp.ok) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                        return resp.json()
                    })
            },
            reorder: (accessToken, id, from, to, platform) => {
                return fetch(getApiDomain() + '/themes/reorder/' + id + '/' + from + '/' + to + '?platform=' + platform, {
                    method: 'POST',
                    headers: authHeaders(accessToken),
                })
                    .then(resp => {
                        if (!resp.ok) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                        return resp.json()
                    })
            },
            export: (accessToken, filter, platform) => {
                let queryString = new URLSearchParams(filter).toString()
                if (queryString !== '') {
                    queryString = '?' + queryString + '&platform=' + platform
                } else {
                    queryString = '?platform=' + platform
                }

                return fetch(getApiDomain() + '/themes/export' + queryString, {method: 'GET', headers: authHeaders(accessToken)})
                    .then(resp => {
                        if (!resp.ok) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                        return resp
                    })
            },
        },
        deviceAffirmations: {
            list: (accessToken, filter, platform) => {
                let queryString = new URLSearchParams(filter).toString()
                if (queryString !== '') {
                    queryString = '?' + queryString + '&platform=' + platform
                } else {
                    queryString = '?platform=' + platform
                }

                return fetch(getApiDomain() + '/device-affirmations' + queryString, {method: 'GET', headers: authHeaders(accessToken)})
                    .then(resp => {
                        if (!resp.ok) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                        return resp.json()
                    })
            },
            export: (accessToken, filter, platform) => {
                let queryString = new URLSearchParams(filter).toString()
                if (queryString !== '') {
                    queryString = '?' + queryString + '&platform=' + platform
                } else {
                    queryString = '?platform=' + platform
                }

                return fetch(getApiDomain() + '/device-affirmations/export' + queryString, {method: 'GET', headers: authHeaders(accessToken)})
                    .then(resp => {
                        if (!resp.ok) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                        return resp
                    })
            },
        },
        admins: {
            list: (accessToken) => {
                return fetch(getApiDomain() + '/admins', {method: 'GET', headers: authHeaders(accessToken)})
                    .then(resp => {
                        if (!resp.ok) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                        return resp.json()
                    })
            },
            create: (accessToken, data) => {
                return fetch(getApiDomain() + '/admins', {method: 'POST', body: JSON.stringify(data), headers: authHeaders(accessToken)})
                    .then(resp => {
                        if (!resp.ok) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                        return resp.json()
                    })
            },
            get: (accessToken, id) => {
                return fetch(getApiDomain() + '/admins/' + id, {method: 'GET', headers: authHeaders(accessToken)})
                    .then(resp => {
                        if (!resp.ok) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                        return resp.json()
                    })
            },
            update: (accessToken, id, data) => {
                return fetch(getApiDomain() + '/admins/' + id, {method: 'PUT', body: JSON.stringify(data), headers: authHeaders(accessToken)})
                    .then(resp => {
                        if (!resp.ok) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                        return resp.json()
                    })
            },
            delete: (accessToken, id) => {
                return fetch(getApiDomain() + '/admins/' + id, {method: 'DELETE', headers: authHeaders(accessToken)})
                    .then(resp => {
                        if (resp.status !== 204) {
                            return resp.json().then(json => {
                                throw parseError(json)
                            });
                        }
                    })
            },
        },
        uploadImage(accessToken, data) {
            return fetch(getApiDomain() + '/images', {method: 'POST', body: data, headers: authHeaders(accessToken)})
                .then(resp => {
                    if (!resp.ok) {
                        return resp.json().then(json => {
                            throw parseError(json)
                        });
                    }
                    return resp.json()
                })
        },
    }
}

export default Api
