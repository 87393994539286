import firebase from 'firebase/app'
import 'firebase/auth';

if (!firebase.apps.length) {
    firebase.initializeApp({
        apiKey: 'AIzaSyBBTCwJdJF6J6O5SZFML5vf1jYEDehuHaY',
        authDomain: 'i-will-i-can-48745.firebaseapp.com',
        projectId: 'i-will-i-can-48745',
        storageBucket: 'i-will-i-can-48745.appspot.com',
        messagingSenderId: '637390288461',
        appId: '1:637390288461:web:aa0e416d24f560df5e8e04',
        measurementId: 'G-XRL4FJCXCN',
    });
}

export const auth = firebase.auth();
const googleProvider = new firebase.auth.GoogleAuthProvider()

export const signInWithGoogle = () => {
    auth.signInWithPopup(googleProvider)
        .then(res => {
            if (!res.user.email) {
                auth.signOut()
            }
        })
        .catch(error => {
            console.log('sign in with google error:', error.message);
        })
}

export const logOut = () => {
    auth.signOut()
        .then(() => {
            console.log('Logged out');
        })
        .catch(error => {
            console.log('Logout error:', error.message);
        })
}
