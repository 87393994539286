import AddIcon from '@material-ui/icons/Add';
import Alert from '@material-ui/lab/Alert';
import Api from '../../utils/api';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Moment from 'react-moment';
import Paper from '@material-ui/core/Paper';
import React, {useContext, useEffect, useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import {Link as RouterLink} from 'react-router-dom';
import {UserContext} from '../../providers/UserProvider';

export default function ListAdmins() {
    const user = useContext(UserContext);
    const [items, setItems] = useState([]);
    const [deleteBox, setDeleteBox] = useState({
        open: false,
        id: '',
        message: '',
    });
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({
        open: false,
        success: false,
        text: '',
    });

    function errorAlert(err) {
        setAlert({open: true, success: false, text: err[0] === undefined ? err : err[0].toUpperCase() + err.slice(1)})
    }

    useEffect(() => {
        if (!user.accessToken) return

        setLoading(true);

        Api().admins.list(user.accessToken)
            .then(data => setItems(data))
            .catch(err => errorAlert(err))
            .then(() => setLoading(false))
    }, [user.accessToken, setLoading]);

    const handleOpenDeleteDialog = (id, text) => {
        setDeleteBox({id: id, message: text, open: true});
    }

    const handleCloseDeleteDialog = () => {
        setDeleteBox({...deleteBox, open: false});
    }

    const handleDelete = () => {
        setLoading(true);

        Api().admins.delete(user.accessToken, deleteBox.id)
            .catch(err => errorAlert(err))
            .then(() => {

                Api().admins.list(user.accessToken)
                    .then(data => setItems(data))
                    .catch(err => errorAlert(err))
                    .then(() => setLoading(false))

            })
            .then(() => {
                setAlert({open: true, success: true, text: 'Admin successfully deleted'})
                handleCloseDeleteDialog()
            })
    }

    const isEmptyDate = s => {
        return s.includes('0001-01-01')
    }

    return <>
        <Typography variant="h6">
            Admins
        </Typography>

        <Box mt={1} mb={2} style={{display: 'flex', justifyContent: 'space-between'}}>
            <Button startIcon={<AddIcon/>} color="primary" variant="contained" component={RouterLink} to="/admins/create">
                Create
            </Button>
        </Box>

        {alert.open && <Alert style={{marginBottom: 10}} severity={alert.success ? 'success' : 'error'} onClose={
            () => setAlert({...alert, open: false})
        }>{alert.text}</Alert>}

        {loading && <LinearProgress/>}

        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell>Enabled</TableCell>
                        <TableCell>Role</TableCell>
                        <TableCell>Created At</TableCell>
                        <TableCell>Updated At</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items && items.map(row => (
                        <TableRow key={row.id}>
                            <TableCell>{row.id}</TableCell>
                            <TableCell>
                                {row.enabled && <CheckIcon style={{color: 'mediumseagreen'}}/>}
                                {!row.enabled && <CheckIcon style={{color: '#faa'}}/>}
                            </TableCell>
                            <TableCell>{row.role}</TableCell>
                            <TableCell>
                                <Moment date={row.created_at} format="YYYY/MM/DD HH:mm:ss"/>
                            </TableCell>
                            <TableCell>
                                {!isEmptyDate(row.updated_at) && <Moment date={row.updated_at} format="YYYY/MM/DD HH:mm:ss"/>}
                            </TableCell>
                            <TableCell>
                                <IconButton
                                    size="small"
                                    component={RouterLink}
                                    to={'/admins/update/' + row.id}
                                    style={{marginRight: 5}}
                                >
                                    <EditIcon/>
                                </IconButton>
                                <IconButton
                                    size="small"
                                    onClick={() => handleOpenDeleteDialog(row.id, row.role)}
                                    color="secondary"
                                >
                                    <DeleteIcon/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

        <Dialog open={deleteBox.open} onClose={() => handleCloseDeleteDialog()}>
            <DialogTitle>Delete admin</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {deleteBox.id && `Admin ${deleteBox.id} will be deleted`}
                    {!deleteBox.id && `${deleteBox.message}`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleCloseDeleteDialog()} autoFocus color="primary">Cancel</Button>
                <Button onClick={() => handleDelete()} variant="contained" color="secondary">Delete</Button>
            </DialogActions>
        </Dialog>
    </>;
}
