import React from 'react';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {Link as RouterLink} from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Form from './Form';

export default class CreateCategory extends React.Component {
    render() {
        return <>
            <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" component={RouterLink} to="/categories">
                    All categories
                </Link>

                <Typography color="textPrimary">Create</Typography>
            </Breadcrumbs>

            <Box mt={3}>
                <Form/>
            </Box>
        </>;
    }
}
