import Typography from '@material-ui/core/Typography';
import React, {useContext, useEffect, useState} from 'react';
import Alert from '@material-ui/lab/Alert';
import LinearProgress from '@material-ui/core/LinearProgress';
import {Box, Button, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import {CSVReader} from 'react-papaparse'
import Api from '../../utils/api';
import {UserContext} from '../../providers/UserProvider';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';

export default function ImportAffirmations() {
    const user = useContext(UserContext);

    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({
        open: false,
        success: false,
        text: '',
    });
    const [category, setCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const [items, setItems] = useState([]);

    function errorAlert(err) {
        setAlert({open: true, success: false, text: err[0] === undefined ? err : err[0].toUpperCase() + err.slice(1)})
    }

    useEffect(() => {
        if (!user.accessToken) return

        setLoading(true);

        Api().categories.list(user.accessToken, user.platform)
            .then(data => setCategories(data))
            .catch(err => errorAlert(err))
            .then(() => setLoading(false))
    }, [user.accessToken, user.platform, setLoading])

    const buttonRef = React.createRef()

    const handleOnFileLoad = data => {
        setLoading(true)

        let objects = [];
        data.forEach(item => {
            if (item.data && item.data[0]) {
                objects.push({id: '', text: item.data[0], name: '', error: ''})
            }
        })
        setItems(objects)
        setLoading(false)
    }

    const handleOnError = (err) => {
        errorAlert(err)
    }

    const handleOpenDialog = e => {
        if (buttonRef.current) {
            buttonRef.current.open(e)
        }
    }

    const saveAffirmations = () => {
        setLoading(true);
        setAlert({...alert, open: false})

        Api().affirmations.import(user.accessToken, {category: category, affirmations: items.map(item => item.text)}, user.platform)
            .then(data => {
                setItems(data)
            })
            .catch(err => errorAlert(err))
            .then(() => setLoading(false))
            .finally(() => setCategory(''))
    }

    const handleChangeCategory = (e) => {
        const {value} = e.target

        setCategory(value)
    }

    const StatusIcon = (e) => {
        if (loading) {
            return <HourglassEmptyIcon className="import-loading"/>
        } else if (e.row.error && e.row.error !== '') {
            return <ErrorIcon className="import-error"/>
        } else if (e.row.id && e.row.id.length > 0) {
            return <CheckCircleIcon className="import-ok"/>
        }

        return ''
    }

    const ImportedItems = () => {
        return <Box>
            <TableContainer component={Paper}>
                <Table size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell/>
                            <TableCell/>
                            <TableCell>ID</TableCell>
                            <TableCell>Text</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((row, index) => (
                            <TableRow key={row.text}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>
                                    <StatusIcon row={row}/>
                                </TableCell>
                                <TableCell>{row.id}</TableCell>
                                <TableCell>{row.text}</TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell style={{color: 'red'}}>{row.error}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box mt={2}>
                <span style={{marginRight: 15}}>Select category</span>

                <Select
                    labelId="Category"
                    value={category}
                    onChange={handleChangeCategory}
                >
                    {categories && categories.map(category => <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>)}
                </Select>
            </Box>

            {category !== '' && <Box mt={2}>
                <Button variant="contained" color="primary" onClick={saveAffirmations}>Confirm import</Button>
            </Box>}
        </Box>
    }

    return <>
        <Typography variant="h6">
            Import Affirmations
        </Typography>

        {alert.open && <Alert style={{marginBottom: 10}} severity={alert.success ? 'success' : 'error'} onClose={
            () => setAlert({...alert, open: false})
        }>{alert.text}</Alert>}

        {loading && <LinearProgress/>}

        <Box mt={1} mb={2}>
            <CSVReader
                ref={buttonRef}
                onFileLoad={handleOnFileLoad}
                onError={handleOnError}
                noClick
                noDrag
                noProgressBar={true}
            >
                {() => <Button color="primary" variant="contained" onClick={handleOpenDialog}>Browse file</Button>}
            </CSVReader>

            {items.length === 0 && <p>Select the CSV file with the affirmative texts separated by lines</p>}
        </Box>

        {items.length > 0 && <ImportedItems/>}
    </>
}
