import React, {createContext, useEffect, useState} from 'react';
import {auth} from '../services/firebase';
import Api from '../utils/api';

export const UserContext = createContext({
    identity: null,
    accessToken: null,
    role: null,
    platform: null,
    dashboardAlert: null,
    loginAlert: null,
    loginLoading: true,
    setAlert: () => {
    },
    setPlatform: () => {
    },
})

const Provider = (props) => {
    const [dashboardAlert, setDashboardAlert] = useState(null)
    const [dashboardPlatform, setDashboardPlatform] = useState('ios')
    const [state, setState] = useState({
        identity: null,
        accessToken: null,
        role: null,
        platform: null,
        dashboardAlert: null,
        loginAlert: null,
        loginLoading: true,
        setAlert: (a) => {
            setDashboardAlert(a)
        },
        setPlatform: (a) => {
            setDashboardPlatform(a)
        },
    })

    useEffect(() => {
        setState({...state, dashboardAlert: dashboardAlert})
        setState({...state, platform: dashboardPlatform})

        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (!user) {
                console.log('clean identity')
                setState({...state, dashboardAlert: dashboardAlert, platform: dashboardPlatform, identity: null, loginLoading: false})
            } else {
                user.getIdToken()
                    .then((token) => {
                        const {displayName, email, photoURL} = user;

                        Api().admins.get(token, email)
                            .then(admin => {
                                setState({
                                    ...state,
                                    identity: {displayName, email, photoURL},
                                    accessToken: token,
                                    role: admin.role,
                                    loginLoading: false,
                                    dashboardAlert: dashboardAlert,
                                    platform: dashboardPlatform,
                                })
                            })
                            .catch(err => {
                                console.log('Backend auth error:', err)

                                setState({
                                    ...state,
                                    identity: null,
                                    accessToken: null,
                                    role: null,
                                    loginAlert: err,
                                    loginLoading: false,
                                    dashboardAlert: dashboardAlert,
                                    platform: dashboardPlatform,
                                })
                            })
                    })
                    .catch(err => {
                        console.log('get id token error:', err)
                    })
            }
        })

        return () => unsubscribe(); // unsubscribing from the listener when the component is unmounting.
    }, [dashboardAlert, dashboardPlatform])

    return <UserContext.Provider value={state}>{props.children}</UserContext.Provider>
}

export default Provider
