import Alert from '@material-ui/lab/Alert';
import Api from '../../utils/api';
import Box from '@material-ui/core/Box';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Moment from 'react-moment';
import Paper from '@material-ui/core/Paper';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import {Button} from '@material-ui/core';
import {UserContext} from '../../providers/UserProvider';
import {debounce} from 'underscore';
import LoadMoreButton from '../base/buttons/LoadMoreButton';

export default function DeviceAffirmations() {
    const user = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({
        open: false,
        success: false,
        text: '',
    });
    const [items, setItems] = useState([]);

    const [filter, setFilter] = useState({
        id: '',
        device_id: '',
        deleted: '',
        text: '',
        date_from: '',
        date_to: '',
        tz: '',
        last_id: '',
    });

    const [downloadLoading, setDownloadLoading] = useState(false);

    function errorAlert(err) {
        setAlert({open: true, success: false, text: err[0] === undefined ? err : err[0].toUpperCase() + err.slice(1)})
    }

    useEffect(() => {
        if (!user.accessToken) return

        setLoading(true);

        Api().deviceAffirmations.list(user.accessToken, {}, user.platform)
            .then(data => {
                setItems(data)
                if (data.length) {
                    setFilter({...filter, last_id: data[data.length - 1].id})
                }
            })
            .catch(err => errorAlert(err))
            .then(() => setLoading(false))
    }, [user.accessToken, user.platform]);

    const handleFiltering = event => {
        const {name, value} = event.target

        const filteredData = {...filter, [name]: value, tz: Intl.DateTimeFormat().resolvedOptions().timeZone};
        setFilter(filteredData)

        debouncedSave(filteredData);
    }

    const debouncedSave = useCallback(debounce(filteredData => filterData(filteredData), 500), [user.platform]);

    const filterData = (filteredData) => {
        if (!user.accessToken) return

        setAlert({...alert, open: false})
        setLoading(true);
        Api().deviceAffirmations.list(user.accessToken, {...filteredData, last_id: ''}, user.platform)
            .then(data => {
                setItems(data)

                if (data.length) {
                    const lastId = data[data.length - 1].id;
                    if (lastId) {
                        setFilter({...filteredData, last_id: lastId})
                    }
                }
            })
            .catch(err => errorAlert(err))
            .then(() => setLoading(false))
    }

    const loadMore = () => {
        if (!user.accessToken) return

        const filteredData = {...filter, tz: Intl.DateTimeFormat().resolvedOptions().timeZone}

        setAlert({...alert, open: false})
        setLoading(true);
        Api().deviceAffirmations.list(user.accessToken, filteredData, user.platform)
            .then(data => {
                setItems(items.concat(data))

                if (data.length) {
                    const lastId = data[data.length - 1].id;
                    if (lastId) {
                        setFilter({...filter, last_id: lastId})
                    }
                }
            })
            .catch(err => errorAlert(err))
            .then(() => setLoading(false))
    }

    const isEmptyDate = s => {
        return s.includes('0001-01-01')
    }

    const downloadCSV = () => {
        if (!user.accessToken) return

        setDownloadLoading(true);
        Api().deviceAffirmations.export(user.accessToken, filter, user.platform)
            // .then(setItems)
            .then(response => response.blob())
            .then(blob => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    'device-affirmations.csv',
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            })
            .catch(err => errorAlert(err))
            .then(() => setDownloadLoading(false))
    }

    return <>
        <Box display="flex" pb={2}>
            <Box flexGrow={1}>
                <Typography variant="h6">
                    Device Affirmations
                </Typography>
            </Box>
            <Box>
                <Button
                    disabled={downloadLoading}
                    variant="contained"
                    startIcon={<CloudDownloadIcon style={{color: 'white'}}/>}
                    color="secondary"
                    style={{color: 'white'}}
                    onClick={downloadCSV}
                >
                    Download all in CSV
                </Button>
            </Box>
        </Box>

        {alert.open && <Alert style={{marginBottom: 10}} severity={alert.success ? 'success' : 'error'} onClose={
            () => setAlert({...alert, open: false})
        }>{alert.text}</Alert>}

        {loading && <LinearProgress/>}
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Device ID</TableCell>
                        <TableCell align="center">Deleted</TableCell>
                        <TableCell>Text</TableCell>
                        <TableCell>Created At</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow className="filter-row">
                        <TableCell style={{padding: '5px 10px'}}>
                            <TextField name="id" value={filter.id} onChange={handleFiltering} style={{width: '100%'}}/>
                        </TableCell>
                        <TableCell style={{padding: '5px 10px'}}>
                            <TextField name="device_id" value={filter.device_id} onChange={handleFiltering} style={{width: '100%'}}/>
                        </TableCell>
                        <TableCell align="center">
                            <Select name="deleted" value={filter.deleted} onChange={handleFiltering}>
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value="yes">Yes</MenuItem>
                                <MenuItem value="no">No</MenuItem>
                            </Select>
                        </TableCell>
                        <TableCell style={{padding: '5px 10px'}}>
                            <TextField name="text" value={filter.text} onChange={handleFiltering} style={{width: '100%'}}/>
                        </TableCell>
                        <TableCell style={{textAlign: 'center'}}>

                            <Tooltip arrow placement="top" title="Set single date to view all items">
                                <div>
                                    <TextField
                                        name="date_from"
                                        label="from"
                                        type="date"
                                        value={filter.date_from}
                                        onChange={handleFiltering}
                                        InputLabelProps={{shrink: true}}
                                        style={{width: 145, marginRight: 5}}
                                    />
                                    <TextField
                                        name="date_to"
                                        label="to"
                                        type="date"
                                        value={filter.date_to}
                                        onChange={handleFiltering}
                                        InputLabelProps={{shrink: true}}
                                        style={{width: 145, marginRight: 5}}
                                    />
                                </div>
                            </Tooltip>

                        </TableCell>
                    </TableRow>
                    {items.map(item => (
                        <TableRow key={item.id}>
                            <TableCell>{item.id}</TableCell>
                            <TableCell>{item.device_id}</TableCell>
                            <TableCell align="center">
                                {!isEmptyDate(item.deleted_at) && <Tooltip title={moment(item.deleted_at).format('YYYY/MM/DD HH:mm:ss')} placement="top">
                                    <HighlightOffIcon style={{color: 'red'}}/>
                                </Tooltip>}
                                {isEmptyDate(item.deleted_at) && <HighlightOffIcon style={{color: '#bbb'}}/>}
                            </TableCell>
                            <TableCell>{item.text}</TableCell>
                            <TableCell>
                                {!isEmptyDate(item.created_at) && <Moment date={item.created_at} format="YYYY/MM/DD HH:mm:ss"/>}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

        <Box mt={2} style={{textAlign: 'center'}}>
            <p>Items count: {items.length}</p>
            <LoadMoreButton disabled={loading} variant="contained" color="primary" onClick={loadMore}>Load more</LoadMoreButton>
        </Box>
    </>
}
