import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import GoogleButton from 'react-google-button';
import React, {useContext} from 'react';
import {Grid} from '@material-ui/core';
import {Redirect} from 'react-router-dom';
import {UserContext} from '../providers/UserProvider';
import {signInWithGoogle} from '../services/firebase';
import {Alert} from '@material-ui/lab';

export default function Login() {
    const user = useContext(UserContext)

    if (user.identity !== null) {
        return <Redirect to="/"/>
    }

    if (user.loginLoading) {
        return <Backdrop open style={{zIndex: 9999, color: '#fff'}}><CircularProgress color="inherit"/></Backdrop>
    }

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            justify="center"
            alignItems="center"
            style={{minHeight: '100vh'}}
        >
            <Grid item xs={3}>
                {user.loginAlert && <Alert severity="error" style={{marginBottom: 10}}>{user.loginAlert}</Alert>}
            </Grid>
            <Grid item xs={3}>
                <div style={{textAlign: 'center'}}>
                    <img src='/logo-shadow.png' width={200} height={200} alt='' style={{marginBottom: 10}}/>
                </div>

                <GoogleButton onClick={signInWithGoogle}/>
            </Grid>
        </Grid>
    )
}
