import AddIcon from '@material-ui/icons/Add';
import Alert from '@material-ui/lab/Alert';
import Api from '../../utils/api';
import Box from '@material-ui/core/Box';
import BrushIcon from '@material-ui/icons/Brush';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Image from '../Image';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Moment from 'react-moment';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import Paper from '@material-ui/core/Paper';
import React, {useContext, useEffect, useState} from 'react';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import {Link as RouterLink} from 'react-router-dom';
import {UserContext} from '../../providers/UserProvider';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import Tooltip from '@material-ui/core/Tooltip';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import {ButtonGroup} from '@material-ui/core';

export default function ThemesList() {
    const user = useContext(UserContext);
    const [items, setItems] = useState([]);
    const [deleteBox, setDeleteBox] = useState({
        open: false,
        id: '',
        message: '',
    });
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({
        open: false,
        success: false,
        text: '',
    });

    const [filter, setFilter] = useState({
        visible: '',
        premium: '',
        is_new: '',
        name: '',
        category: '',
        paintIcon: '',
        fontName: '',
    });

    const [selected, setSelected] = useState([]);

    const [downloadLoading, setDownloadLoading] = useState(false);

    function errorAlert(err) {
        setAlert({open: true, success: false, text: err[0] === undefined ? err : err[0].toUpperCase() + err.slice(1)})
    }

    useEffect(() => {
        if (!user.accessToken) return

        setLoading(true);

        Api().themes.list(user.accessToken, user.platform)
            .then(data => setItems(data))
            .catch(err => errorAlert(err))
            .then(() => setLoading(false))
    }, [user.accessToken, user.platform, setLoading])

    const handleOpenDeleteDialog = (id) => {
        setDeleteBox({id: id, open: true});
    }

    const handleCloseDeleteDialog = () => {
        setDeleteBox({...deleteBox, open: false});
    }

    const handleDelete = () => {
        setLoading(true);

        Api().themes.delete(user.accessToken, deleteBox.id, user.platform)
            .catch(err => errorAlert(err))
            .then(() => {

                Api().themes.list(user.accessToken, user.platform)
                    .then(data => setItems(data))
                    .catch(err => errorAlert(err))
                    .then(() => setLoading(false))

            })
            .then(() => {
                setAlert({open: true, success: true, text: 'Theme successfully deleted'})
                handleCloseDeleteDialog()
            })
    }

    const handleFiltering = (event) => {
        const {name, value} = event.target

        setFilter({...filter, [name]: value})
    }

    const getFilteredItems = () => {
        if (filter.visible === '' && filter.premium === '' && filter.is_new === '' && filter.name === '' && filter.category === '' && filter.paintIcon === '' && filter.fontName === '') {
            return items
        }

        let list = items;

        if (filter.premium !== '') {
            list = list.filter(item => item.premium === (filter.premium === 'yes'))
        }
        if (filter.visible !== '') {
            list = list.filter(item => item.visible === (filter.visible === 'yes'))
        }
        if (filter.is_new !== '') {
            list = list.filter(item => item.is_new === (filter.is_new === 'yes'))
        }
        if (filter.paintIcon !== '') {
            list = list.filter(item => item.paintIcon === (filter.paintIcon === 'yes'))
        }
        if (filter.fontName !== '') {
            list = list.filter(item => item.font_name.toLowerCase().includes(filter.fontName.toLowerCase().trim()))
        }
        if (filter.name !== '') {
            list = list.filter(item => item.name.toLowerCase().includes(filter.name.toLowerCase().trim()))
        }
        if (filter.category !== '') {
            list = list.filter(item => item.category.toLowerCase().includes(filter.category.toLowerCase().trim()))
        }

        return list;
    }

    const handleChangeSelected = (event) => {
        if (event.target.checked) {
            setSelected([...selected, event.target.value]);
        } else {
            setSelected([...selected.filter(id => id !== event.target.value)])
        }
    }

    const handleSelectALL = () => {
        setSelected(items.map(item => item.id))
    }

    const handleSelectNone = () => {
        setSelected([])
    }

    const handleToggleSelectedVisible = () => {
        setLoading(true);

        Api().themes.toggle(user.accessToken, selected, [], [], [], user.platform)
            .then(() => {
                Api().themes.list(user.accessToken, user.platform)
                    .then(data => setItems(data))
                    .catch(err => errorAlert(err))
                    .then(() => setLoading(false))
            })
            .catch(err => errorAlert(err))
    }

    const handleToggleSelectedPremium = () => {
        setLoading(true);

        Api().themes.toggle(user.accessToken, [], selected, [], [], user.platform)
            .then(() => {
                Api().themes.list(user.accessToken, user.platform)
                    .then(data => setItems(data))
                    .catch(err => errorAlert(err))
                    .then(() => setLoading(false))
            })
            .catch(err => errorAlert(err))
    }

    const handleToggleSelectedPaintIcon = () => {
        setLoading(true);

        Api().themes.toggle(user.accessToken, [], [], selected, [], user.platform)
            .then(() => {
                Api().themes.list(user.accessToken, user.platform)
                    .then(data => setItems(data))
                    .catch(err => errorAlert(err))
                    .then(() => setLoading(false))
            })
            .catch(err => errorAlert(err))
    }

    const handleToggleSelectedIsNew = () => {
        setLoading(true);

        Api().themes.toggle(user.accessToken, [], [], [], selected, user.platform)
            .then(() => {
                Api().categories.list(user.accessToken, user.platform)
                    .then(data => setItems(data))
                    .catch(err => errorAlert(err))
                    .then(() => setLoading(false))
            })
            .catch(err => errorAlert(err))
    }

    const handleShowDeleteSelected = () => {
        setDeleteBox({...deleteBox, id: '', message: 'Delete selected items?', open: true});
    }

    const handleDeleteSelected = () => {
        setLoading(true);

        Api().themes.deleteList(user.accessToken, selected, user.platform)
            .then(() => {
                Api().themes.list(user.accessToken, user.platform)
                    .then(data => setItems(data))
                    .catch(err => errorAlert(err))
                    .then(() => setLoading(false))

                setSelected([]);
                handleCloseDeleteDialog();
            })
            .catch(err => errorAlert(err))
    }

    const onDragEnd = result => {
        if (!result.destination || user.role === 'viewer') {
            return;
        }

        setItems(reorder(
            items,
            result.source.index,
            result.destination.index,
        ));

        setLoading(true);
        Api().themes.reorder(user.accessToken, result.draggableId, result.source.index, result.destination.index, user.platform)
            .then(data => setItems(data))
            .catch(err => errorAlert(err))
            .then(() => setLoading(false))
    }

    const reorder = (arr, startIndex, endIndex) => {
        if (endIndex >= arr.length) {
            var k = endIndex - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(endIndex, 0, arr.splice(startIndex, 1)[0]);

        return arr;
    };

    const displayHoverImage = (e) => {
        const clone = document.createElement('img');

        const ratio = e.target.naturalWidth / e.target.naturalHeight;

        const height = 280
        const width = height * ratio

        clone.src = e.target.src;
        clone.id = 'cloned-' + e.target.id;
        clone.classList.add('hovered-image');
        clone.style.left = (e.target.x - width - 5) + 'px';
        clone.style.top = (e.pageY - height) + 'px';
        clone.style.position = 'absolute';
        clone.style.width = width + 'px';
        clone.style.height = height + 'px';

        document.body.appendChild(clone);
    }

    const hideHoverImage = (e) => {
        document.getElementById('cloned-' + e.target.id).remove()
    }

    const downloadCSV = () => {
        if (!user.accessToken) return

        setDownloadLoading(true);
        Api().themes.export(user.accessToken, filter, user.platform)
            // .then(setItems)
            .then(response => response.blob())
            .then(blob => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    'themes.csv',
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            })
            .catch(err => errorAlert(err))
            .then(() => setDownloadLoading(false))
    }

    return <>
        <Box display="flex" pb={2}>
            <Box flexGrow={1}>
                <Typography variant="h6">
                    Themes
                </Typography>
            </Box>
            <Box>
                <ButtonGroup>
                    <Button startIcon={<AddIcon/>} color="primary" variant="contained" component={RouterLink} to={'/themes/create'}>
                        Create
                    </Button>

                    {user.role !== 'viewer' && <Button
                        disabled={downloadLoading}
                        variant="contained"
                        startIcon={<CloudDownloadIcon style={{color: 'white'}}/>}
                        color="secondary"
                        style={{color: 'white'}}
                        onClick={downloadCSV}
                    >
                        Download all in CSV
                    </Button>}
                </ButtonGroup>
            </Box>
        </Box>

        {alert.open && <Alert style={{marginBottom: 10}} severity={alert.success ? 'success' : 'error'} onClose={
            () => setAlert({...alert, open: false})
        }>{alert.text}</Alert>}

        {loading && <LinearProgress/>}

        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table" ref={provided.innerRef}>
                            <TableHead>
                                <TableRow>
                                    <TableCell/>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Position</TableCell>
                                    <TableCell>Visible</TableCell>
                                    <TableCell>Premium</TableCell>
                                    <TableCell>Paint Icon</TableCell>
                                    <TableCell>New</TableCell>
                                    <TableCell>Image</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Category</TableCell>
                                    <TableCell>Font Color</TableCell>
                                    <TableCell>Font Name</TableCell>
                                    <TableCell>Created at</TableCell>
                                    <TableCell/>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items && items.length > 0 && selected.length === 0 && <TableRow className="filter-row">
                                    <TableCell/>
                                    <TableCell/>
                                    <TableCell/>
                                    <TableCell>
                                        <Select name="visible" value={filter.visible} onChange={handleFiltering}>
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="yes">Yes</MenuItem>
                                            <MenuItem value="no">No</MenuItem>
                                        </Select>
                                    </TableCell>
                                    <TableCell>
                                        <Select name="premium" value={filter.premium} onChange={handleFiltering}>
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="yes">Yes</MenuItem>
                                            <MenuItem value="no">No</MenuItem>
                                        </Select>
                                    </TableCell>
                                    <TableCell/>
                                    <TableCell>
                                        <Select name="is_new" value={filter.is_new} onChange={handleFiltering}>
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value="yes">Yes</MenuItem>
                                            <MenuItem value="no">No</MenuItem>
                                        </Select>
                                    </TableCell>
                                    <TableCell/>
                                    <TableCell>
                                        <TextField name="name" value={filter.name} onChange={handleFiltering} style={{width: '95%'}}/>
                                    </TableCell>
                                    <TableCell>
                                        <TextField name="category" value={filter.category} onChange={handleFiltering} style={{width: '95%'}}/>
                                    </TableCell>
                                    <TableCell/>
                                    <TableCell>
                                        <TextField name="fontName" value={filter.fontName} onChange={handleFiltering} style={{width: '100%'}}/>
                                    </TableCell>
                                    <TableCell/>
                                    <TableCell/>
                                </TableRow>}
                                {items && items.length > 0 && selected.length > 0 && <TableRow className="filter-row">
                                    <TableCell colSpan={12} style={{padding: 16}} className="select-options">
                                        Select -&nbsp;
                                        <Button color="primary" onClick={handleSelectALL}>all</Button>,&nbsp;
                                        <Button color="primary" onClick={handleSelectNone}>none</Button>.
                                        Actions with selected -&nbsp;
                                        <Button color="primary" onClick={handleToggleSelectedVisible}>toggle visible</Button>,&nbsp;
                                        <Button color="primary" onClick={handleToggleSelectedPremium}>toggle premium</Button>,&nbsp;
                                        <Button color="primary" onClick={handleToggleSelectedPaintIcon}>toggle paint icon</Button>,&nbsp;
                                        <Button color="primary" onClick={handleToggleSelectedIsNew}>toggle new</Button>,&nbsp;
                                        <Button color="primary" onClick={handleShowDeleteSelected}>delete</Button>.
                                    </TableCell>
                                </TableRow>}
                                {items && getFilteredItems().map((row, index) => (
                                    <Draggable key={row.id} draggableId={row.id} index={index} isDragDisabled={user.role === 'viewer'}>
                                        {(provided, snapshot) => (
                                            <TableRow key={row.id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                <TableCell>
                                                    {
                                                        user.role !== 'viewer' &&
                                                        <Checkbox value={row.id} checked={selected.includes(row.id)} onChange={handleChangeSelected}/>
                                                    }
                                                </TableCell>
                                                <TableCell>{row.id}</TableCell>
                                                <TableCell>{row.position}</TableCell>
                                                <TableCell>
                                                    {row.visible && <VisibilityIcon style={{color: 'mediumseagreen'}}/>}
                                                    {!row.visible && <VisibilityOffIcon style={{color: '#bbb'}}/>}
                                                </TableCell>
                                                <TableCell>
                                                    {row.premium && <MonetizationOnIcon style={{color: 'gold'}}/>}
                                                    {!row.premium && <MoneyOffIcon style={{color: '#bbb'}}/>}
                                                </TableCell>
                                                <TableCell>
                                                    <BrushIcon style={{color: row.paint_icon ? 'steelblue' : '#bbb'}}/>
                                                </TableCell>
                                                <TableCell>
                                                    {row.is_new && <NewReleasesIcon style={{color: '#0094ff'}}/>}
                                                    {!row.is_new && <NewReleasesIcon style={{color: '#bbb'}}/>}
                                                </TableCell>
                                                <TableCell>
                                                    <Image
                                                        disableSpinner
                                                        src={row.image}
                                                        aspectRatio={0.66}
                                                        onMouseEnter={displayHoverImage}
                                                        onMouseOut={hideHoverImage}
                                                        id={'image-' + row.id}
                                                    />
                                                </TableCell>
                                                <TableCell>{row.name}</TableCell>
                                                <TableCell>{row.category}</TableCell>
                                                <TableCell>
                                                    <Tooltip title={row.font_color} placement="top">
                                                        <span className="font-color" style={{backgroundColor: row.font_color}}>&nbsp;</span>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell>{row.font_name}</TableCell>
                                                <TableCell>
                                                    <Moment date={row.created_at} format="YYYY/MM/DD HH:mm:ss"/>
                                                </TableCell>
                                                <TableCell>
                                                    {user.role !== 'viewer' && <>
                                                        <IconButton
                                                            size="small"
                                                            component={RouterLink}
                                                            to={'/themes/update/' + row.id}
                                                            style={{marginRight: 5}}
                                                        >
                                                            <EditIcon/>
                                                        </IconButton>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => handleOpenDeleteDialog(row.id)}
                                                            color="secondary"
                                                        >
                                                            <DeleteIcon/>
                                                        </IconButton>
                                                    </>}
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Droppable>
        </DragDropContext>

        <Dialog open={deleteBox.open} onClose={() => handleCloseDeleteDialog()}>
            <DialogTitle>Delete theme</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {deleteBox.id && `Theme #${deleteBox.id} will be deleted`}
                    {!deleteBox.id && `${deleteBox.message}`}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleCloseDeleteDialog()} autoFocus color="primary">Cancel</Button>
                <Button onClick={() => deleteBox.id ? handleDelete() : handleDeleteSelected()} variant="contained" color="secondary">Delete</Button>
            </DialogActions>
        </Dialog>
    </>
}
